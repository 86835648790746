@use 'mixins' as *;
/* Animations */
@-webkit-keyframes spin {
  from {
    @include transform(rotate(0deg));
  }
  to {
    @include transform(rotate(360deg));
  }
}

/* Spin an element clockwise */

@keyframes spin {
  from {
    @include transform(rotate(0deg));
  }
  to {
    @include transform(rotate(360deg));
  }
}

@-webkit-keyframes fadeIn {
  from {
    display: none;
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Fade in an element */

@keyframes fadeIn {
  from {
    display: none;
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    z-index: 1;
    display: none;
  }
}

/* Fade out an element */

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    z-index: 1;
    display: none;
  }
}

@-webkit-keyframes slideOutBottom {
  from {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
  }
  to {
    position: fixed;
    top: 100vh;
    left: 0;
    opacity: 0;
  }
}

/* Slide an element to the bottom of the screen whilst making it transparent */

@keyframes slideOutBottom {
  from {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
  }
  to {
    position: fixed;
    top: 100vh;
    left: 0;
    opacity: 0;
  }
}
