@mixin transform($param) {
  -webkit-transform: $param;
  transform: $param;
}

@mixin animation($param) {
  -webkit-animation: $param;
  animation: $param;
}

@mixin transition($param) {
  -webkit-transition: $param;
  -o-transition: $param;
  transition: $param;
}

@mixin transformTranslate($param, $param2) {
  -webkit-transform: translate($param, $param2);
  -ms-transform: translate($param, $param2);
  transform: translate($param, $param2);
}

@mixin box-sizing($param) {
  -webkit-box-sizing: $param;
  box-sizing: $param;
}
@mixin button {
  height: 49px;
  width: 49px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
}
@mixin background-image($id, $url, $param...) {
  /* Set different image sources for the header #{$id} */
  .noJs #{$id} {
    background: transparent url(#{$url}.png) $param;
  }
  .no-webp #{$id} {
    background: transparent url(#{$url}.png) $param;
  }
  .webp #{$id} {
    background: transparent url(#{$url}.webp) $param;
  }
}
