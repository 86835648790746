@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Questrial&family=Ubuntu:wght@300&display=swap");
* {
  color: #242424;
  background-color: #f2f2f2;
  font-family: "Ubuntu", sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: normal;
  /* Makes header text look the same between gecko and blink */
}

/* Sets default styling */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Questrial", sans-serif;
}

/* Set default font for h[1-6] tags */
/* For <h[1-6]> tags that need to be centered */
.center {
  text-align: center;
}

/* Body and html don't scroll */
body,
html {
  margin: 0;
  padding: 0;
  position: fixed;
  overflow: hidden;
  font-size: 100%;
}

/* For making in page navigation smooth */
html {
  scroll-behavior: smooth;
}

body > #wrapper {
  scroll-behavior: smooth;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  /* This is changed after the loading screen disappears.
  The purpose of this is to make the scroll bar appear only when it's ready*/
  -webkit-overflow-scrolling: touch;
  /* Enable momentum and smooth scrolling for IOS users */
}

/* Setup scrolling on the scroll wrapper */
/* Main */
footer.col-12 {
  padding-bottom: 30vh;
}

/* Let users scroll past the content to center the content vertically so that
 they don't have to strain their eyes or move thier necks */
hr {
  margin: 0;
}

/* Make the hr tag look cleaner */
/* Breakup long urls on safari */
.reference {
  word-wrap: break-word;
}

/* Make everything inside the 'document' including list bullet points */
ul {
  list-style-position: inside;
  margin: 0;
}

/* Animations */
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* Spin an element clockwise */
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes fadeIn {
  from {
    display: none;
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Fade in an element */
@keyframes fadeIn {
  from {
    display: none;
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    z-index: 1;
    display: none;
  }
}
/* Fade out an element */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    z-index: 1;
    display: none;
  }
}
@-webkit-keyframes slideOutBottom {
  from {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
  }
  to {
    position: fixed;
    top: 100vh;
    left: 0;
    opacity: 0;
  }
}
/* Slide an element to the bottom of the screen whilst making it transparent */
@keyframes slideOutBottom {
  from {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
  }
  to {
    position: fixed;
    top: 100vh;
    left: 0;
    opacity: 0;
  }
}
/* Responsive */
/* Mobile */
[class*=col-] {
  width: 100%;
  float: left;
  padding: 10px;
}

.mobileOnly {
  display: inline-block;
}

/* Make mobile only elements appear */
.tabletAbove {
  display: none;
}

/* disappear tablet only elements */
.desktopOnly {
  display: none;
}

/* disappear desktop only elements */
.picture {
  width: unset;
}

/* let pictures be reactive */
/* Tablet */
@media only screen and (min-width: 500px) {
  .tabletAbove {
    display: inline-block;
  }

  /* Make tablet elements appear */
  .mobileOnly {
    display: none;
  }

  /* disappear mobile only elements */
}
/* Desktop */
@media only screen and (min-width: 768px) {
  .col-1 {
    width: 8.33%;
  }

  .col-2 {
    width: 16.66%;
  }

  .col-3 {
    width: 25%;
  }

  .col-4 {
    width: 33.33%;
  }

  .col-5 {
    width: 41.66%;
  }

  .col-6 {
    width: 50%;
  }

  .col-7 {
    width: 58.33%;
  }

  .col-8 {
    width: 66.66%;
  }

  .col-9 {
    width: 75%;
  }

  .col-10 {
    width: 83.33%;
  }

  .col-11 {
    width: 91.66%;
  }

  .col-12 {
    width: 100%;
  }

  /* Set column width for desktop */
  .desktopOnly {
    display: inline-block;
  }

  /* Make desktop only elements appear */
}
/* styles.scss © Copyright 2020 Justin Muirhead under the BSD 3-Clause License
code snippets taken from: https://www.w3schools.com/
and: https://github.com/jhay0112/jhay0112.github.io/
go visit: https://jordanhay.tk/
Prefixed by https://autoprefixer.github.io (PostCSS: v7.0.29, autoprefixer: v9.7.6) */
/* SPDX-License-Identifier: BSD-3-Clause */
/* Loading Screen */
#loadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}
#loadingScreen #logo {
  position: relative;
  top: 40vh;
  left: 50vw;
  max-height: 100%;
  padding: 0;
  margin-top: -100px;
  margin-left: -85px;
}
#loadingScreen #spinner {
  background-color: transparent;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
  position: relative;
  top: 50vh;
  left: 50vw;
  max-height: 100%;
  padding: 0;
  margin-top: -25px;
  margin-left: -110px;
  /* Negative margin for centering, equation: minus the width of the logo divided by two. */
  /* This fixes a problem where the spinner is offset by half the width of the logo. */
}

/* Makes the loading screen the size of the viewport
 and centers images horizontally and animates the loading spinner */
/* Menu */
#menu {
  height: 100vh;
  width: 0;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  cursor: pointer;
}
#menu #menuClose {
  position: absolute;
  top: 15px;
  right: 35px;
  font-size: 2.1875em;
  padding: 0px 10px;
}
#menu a {
  padding: 8px;
  text-decoration: none;
  font-size: 1.25em;
  color: #f2f2f2;
  display: block;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background-color: transparent;
}
#menu a:hover, #menu a:focus {
  background-color: #f6e44a;
  color: #242424;
}
#menu #menuContent {
  position: relative;
  top: 25vh;
  width: 100vw;
  text-align: center;
  margin-top: 30px;
  background-color: transparent;
}

/* Sets the size of the menu overlay, positions the <a> tags,
 and positions the overlay close button, as well as styling all overlay items */
/* Navbar */
#navbar {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 4;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
#navbar #nav {
  padding: 0;
}
#navbar #nav a {
  float: left;
  text-align: center;
  padding: 15px;
  text-decoration: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
#navbar #nav a:hover, #navbar #nav a:focus {
  background-color: #f6e44a;
}
#navbar #nav #menuOpen {
  height: 49px;
  width: 49px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  float: right;
}
#navbar #nav #menuOpen:hover, #navbar #nav #menuOpen:focus {
  background-color: #f6e44a;
}
#navbar #nav #navbarTux {
  height: 49px;
  width: 49px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  float: left;
  background: transparent url(../favicon.ico) center no-repeat;
  max-height: 100%;
}

/* Sets the location and size of the navbar and it's children */
/* Progress Bar */
#progressContainer {
  width: 100vw;
  height: 8px;
  padding: 0;
  background-color: #242424;
}
#progressContainer #progressBar {
  width: 0vw;
  height: 8px;
  background-color: #f6e44a;
}

/* Sets the size and location of the progress bar under the navbar */
/* Back to Top Button */
#backToTopButton {
  background-color: #242424;
  color: #f2f2f2;
  height: 40px;
  width: 40px;
  border-radius: 25px;
  border: none;
  outline: none;
  text-align: center;
  font-size: 1.3334em;
  position: fixed;
  bottom: 30px;
  right: 30px;
  margin: 0;
  margin-left: -25px;
  padding: 0;
  z-index: 3;
  -webkit-animation: fadeOut 0.5s forwards;
  animation: fadeOut 0.5s forwards;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}
#backToTopButton:hover {
  background-color: #f6e44a;
  color: #242424;
}

/* Sets the size and location of the back to top button */
/* Page Headers and Images */
/* Set different image sources for the header #title */
.noJs #title {
  background: transparent url(../img/200px-Tux.svg-opaque.png) center space;
}

.no-webp #title {
  background: transparent url(../img/200px-Tux.svg-opaque.png) center space;
}

.webp #title {
  background: transparent url(../img/200px-Tux.svg-opaque.webp) center space;
}

/* Set different image sources for the header #unix */
.noJs #unix {
  background: transparent url(../img/PDP7.png) center/cover no-repeat;
}

.no-webp #unix {
  background: transparent url(../img/PDP7.png) center/cover no-repeat;
}

.webp #unix {
  background: transparent url(../img/PDP7.webp) center/cover no-repeat;
}

/* Set different image sources for the header #gnuAndLinux */
.noJs #gnuAndLinux {
  background: transparent url(../img/X-Window-System-opaque.png) center/cover no-repeat;
}

.no-webp #gnuAndLinux {
  background: transparent url(../img/X-Window-System-opaque.png) center/cover no-repeat;
}

.webp #gnuAndLinux {
  background: transparent url(../img/X-Window-System-opaque.webp) center/cover no-repeat;
}

/* Set different image sources for the header #shinyShinyDistros */
.noJs #shinyShinyDistros {
  background: transparent url(../img/sele_linuxmint_center-opaque.png) center/cover no-repeat;
}

.no-webp #shinyShinyDistros {
  background: transparent url(../img/sele_linuxmint_center-opaque.png) center/cover no-repeat;
}

.webp #shinyShinyDistros {
  background: transparent url(../img/sele_linuxmint_center-opaque.webp) center/cover no-repeat;
}

/* Sets the sources for the background images as well as styling information */
/* Header */
header {
  margin-top: 57px;
  height: calc(100vh - 57px);
}
header#unix {
  margin-top: unset;
  height: 100vh;
}
header h1 {
  padding: 15px;
  border: 2px solid #242424;
  font-size: 3.3125em;
  margin: 0;
  text-align: center;
}

/* Sets the positioning of the header and the styling of the header text */
.wordContainer {
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: transparent;
}

/* Sets the positioning of the header text */
/* Quotes */
/* Adding an unnecessary class makes it more specific and overrules other rules */
blockquote.quote {
  border-left: 3px solid #8b8b8b;
  margin: 1em 0;
  padding-left: 40px;
}
blockquote.quote .indent {
  padding-left: 1.6em;
}

/* Sets the indent of the quote and the extra indent of the indent
class for indents in quotes, also sets the border-left */
/* Figure and Images */
figure {
  margin: 0;
}
figure figcaption {
  text-align: center;
}
figure figcaption p {
  margin: 0;
}
figure img {
  display: inline-block;
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  max-width: 100%;
}

/* Sets styling and positioning for the imagine, the figure,
 and the image caption and it's text */
/* Page Switcher */
#switcher {
  padding: 0;
  display: inline-block;
  position: relative;
  left: 50%;
  -webkit-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}
#switcher a {
  display: inline-block;
  text-align: center;
  padding: 15px;
  text-decoration: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
#switcher a:hover, #switcher a:focus {
  background-color: #f6e44a;
}

/* Sets the positioning and styling for the page switcher */
/* Footer */
footer {
  text-align: center;
}

/* Positioning for the footer text */
/* 404 */
div.no {
  text-align: center;
  padding-bottom: 30px;
  margin-top: 57px;
}
div.no a.no {
  font: 33.33vw "Questrial", sans-serif;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
div.no a.no:hover, div.no a.no:focus {
  background-color: #f6e44a;
}

/* Sets the styling and positioning for the 404
page button that takes you back to the index.html page */

