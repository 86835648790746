@use 'variables' as *;
@use 'mixins' as *;
@import url("https://fonts.googleapis.com/css2?family=Questrial&family=Ubuntu:wght@300&display=swap");
* {
  color: $color;
  background-color: $bgcolor;
  font-family: "Ubuntu", sans-serif;
  @include box-sizing(border-box);
  font-weight: normal;
  /* Makes header text look the same between gecko and blink */
}
/* Sets default styling */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Questrial", sans-serif;
}
/* Set default font for h[1-6] tags */

/* For <h[1-6]> tags that need to be centered */

.center {
  text-align: center;
}

/* Body and html don't scroll */

body,
html {
  margin: 0;
  padding: 0;
  position: fixed;
  overflow: hidden;
  font-size: 100%;
}

/* For making in page navigation smooth */

html {
  scroll-behavior: smooth;
}

body > #wrapper {
  scroll-behavior: smooth;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  /* This is changed after the loading screen disappears.
  The purpose of this is to make the scroll bar appear only when it's ready*/
  -webkit-overflow-scrolling: touch;
  /* Enable momentum and smooth scrolling for IOS users */
}

/* Setup scrolling on the scroll wrapper */

/* Main */
footer.col-12 {
  padding-bottom: 30vh;
}

/* Let users scroll past the content to center the content vertically so that
 they don't have to strain their eyes or move thier necks */

hr {
  margin: 0;
}

/* Make the hr tag look cleaner */

/* Breakup long urls on safari */

.reference {
  word-wrap: break-word;
}

/* Make everything inside the 'document' including list bullet points */

ul {
  list-style-position: inside;
  margin: 0;
}
